import React, {useState, useEffect} from 'react'
import { currentLang } from '../../../utils/functions'
import { Link } from 'react-router-dom';

const images2_nl_folder = "https://www.grutto.com/storage/NL/farmshop/banners/";
const images2_de_folder = "https://www.kaufnekuh.de/storage/DE/farmshop/banners/";

function BrandBanner({bannerId, isMobile, lang}) {
  const slugs = {
    'nl':[
      'natuurlijke-processen',
      'onze-vleespakketten',
      'kop-tot-staart',
      'scharrelkippen',
      'weidevarkens',
      'lokaal-lamsvlees-van-nederlandse-boeren',
      'duurzaam-gevangen-vis-door-kleinschalige-visserijen',
      'nederlandse-natuurgebieden',
    ],
    'de':[
      'tierschutz',
      'unsere-fleischpakete',
      'versand',
      'schwanz-bis-schnauze',
      'unsere-huhner-laufen-frei-herum',
      'unsere-schweine-konnen-immer-an-die-frische-luft'
    ],
  }

  var slug = slugs[currentLang()][bannerId];
  var image2_url = images2_nl_folder+slug+'-'+(isMobile?'mobile':'desktop')+'.jpg';
  var image4_url = images2_de_folder+slug+'-'+(isMobile?'mobile':'desktop')+'.jpg';

  return (
    <>
      {currentLang() == "nl" &&
        <div className={'banner-holder id-' + bannerId}>
          <div className='banner-link'>
            <Link to={"/"+currentLang()+"/"+slug}>
              <img src={image2_url} />
            </Link>
          </div>
        </div>
      }
      {currentLang() == "de" &&
        <div className={'banner-holder id-' + bannerId}>
          <div className='banner-link'>
            <Link to={"/"+currentLang()+"/"+slug}>
              <img src={image4_url} />
            </Link>
          </div>
        </div>
      }
    </>
  )
}

export default BrandBanner