import React, {useState, useEffect, Fragment} from 'react'
import flatstore from 'flatstore';
import { currentLang } from '../../../../utils/functions';
import parse from 'html-react-parser';
import W from '../../../common/Word';
import jsonp from 'jsonp';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function ProductNewsletter({productId, settings_data, mailchimp_data, animaltype, status}) {
    const [params, setParams] = useState(null);
    const [email, setEmail] = useState(null);
    const [messageType, setMessageType] = useState(null);
    const [message, setMessage] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if(settings_data){
            setParams(settings_data);
        }
    }, [settings_data])
       

    const handleSubmit = e => {
        e.preventDefault();
        if(email){
            const url = 'https://gfecpao8yc.execute-api.eu-central-1.amazonaws.com/Prod/klaviyo/back-in-stuck-subscriptions';
            const postData = {
                email: email,
                PackageSlugOrAttributeName: productId
            };

            setMessageType(null);

            try {
                setDisabled(true);
                axios.post(url, postData, {
                    headers: {
                        'Authorization': 'Bearer b48f0519fc5bd4fcaacbd0fc5a90074a43d7be80b900c966019dd9b6fa9ea887',
                        'x-country': currentLang()
                    },
                    timeout: 10000
                }).then(response => {
                    if (response && response.data) {
                        setMessageType('success');
                        setMessage(t('newsletter.product_success'));
                    }
                    setDisabled(false);
                }).catch(error => {
                    setMessageType('error');
                    setMessage(t('newsletter.subscription_error'));
                    setDisabled(false);
                    console.log(error);
                });
            } catch(err) {
                setMessageType('error'); 
                setMessage(t('newsletter.subscription_error'));
                setDisabled(false);
                console.log(err);
            }
            
            // I'll just leave the old code here in case we need it again, if its not needed, just remove it, or i'll remove it later in the future commits.

                // var url = '/'+currentLang()+'/shop/wp-json/grutto/v2/klaviyo/soldout_subscribe';
                // var postdata = new FormData();
                // postdata.append('email', email);
                // postdata.append('slug', productId);

                // setMessageType(null);

                // try{
                //     setDisabled(true);
                //     axios.post(url, postdata, {timeout:10000}).then(response=>{
                //         if (response && response.data) {
                //             setMessageType(response.data.message);
                //             var msg = t('newsletter.product_success');
                //             if(response.data.message){
                //                 msg = response.data.message
                //             }
                //             setMessage(parse(msg));

                //         }
                //         setDisabled(false);

                //     }).catch(() => {

                //         setMessageType('error');
                //         setMessage(t('newsletter.subscription_error'));
                //         setDisabled(false);
                        
                //     });
                // }catch(err){
                //     setMessageType('error');
                //     setMessage(t('newsletter.subscription_error'));
                //     setDisabled(false);
                //     console.log(err);
                // }
        }

    }

    return (
        <Fragment>
        {params && productId &&
        <div className="newsletter">
            <div className="heading--h6 mb-2"><W>newsletter.status.{status}</W></div>
            <p><W>newsletter.message</W></p>
            <form method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate onSubmit={(e)=>{handleSubmit(e)}}>
                
                <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group">
                        <input type="email" value={email ? email : ''} placeholder={t('newsletter.placeholder')} name="EMAIL" className="required email form-control" id="mce-EMAIL" onChange={(e)=>{setEmail(e.target.value)}} />
                        <input type="submit" value={t('newsletter.button')} name="subscribe" id="mc-embedded-subscribe" className={"btn btn--red"+(disabled ? " btn--disable" : "")} disabled={disabled} />
                    </div>
                    <div className="mc-field-group input-group" style={{display: "none"}}>
                        <ul>
                            <li>
                                <input type="checkbox" value={productId} name={"group["+params[currentLang()+'.mailchimp_group_id']+"]["+productId+"]"} onChange={()=>{}} id={"mce-group["+params[currentLang()+'.mailchimp_group_id']+"]-30-7"} checked />
                                <label>Lam</label>
                            </li>
                        </ul>
                    </div>
                    {messageType && message &&
                    <div className={"message message--"+messageType}>
                        {parse(message)}
                    </div>
                    }
                    <div id="mce-responses" className="clear">
                        <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                        <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                    </div>
                </div>
            </form>
        </div>
        }
        </Fragment>
    )
}

export default flatstore.connect(['settings_data', 'mailchimp_data'])(ProductNewsletter);
