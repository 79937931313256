import React, { useEffect, useState } from 'react';
import W from '../../../components/common/Word';
import { useTranslation } from 'react-i18next';

const ActionTypes = {
  ADD: 'add',
  REMOVE: 'remove'
};

function FarmshopCartButton({ bundle_id, quantity, addToCartCallback, slug, stock, minStock, title, price }) {
  const [value, setValue] = useState(quantity);
  const [maxQtyMsgVisible, setMaxQtyMsgVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setValue(quantity);
    updateProductGlobalState(bundle_id, quantity);
  }, [quantity, bundle_id]);

  useEffect(() => {
    if (maxQtyMsgVisible) {
      const timer = setTimeout(() => {
        setMaxQtyMsgVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [maxQtyMsgVisible]);

  const updateProductGlobalState = (id, quantity) => {
    window.dataLayer = window.dataLayer || [];
    window[`product_${id}`] = quantity;
  };

  const gtagEvent = (eventType) => {
    window.dataLayer.push({
        'productInfo': {
          'product_id': slug,
          'title': title,
          'shop_package_price': price,
          'category_slug': 'grutto_custom_organic',
          'shop_animal_brand': 'Farmshop',
          'flag': 'added_to_cart_tracking'
        },
        'event': eventType
    });
  };

  const addtocart = async (action) => {
    let newVal = value;

    switch (action) {
      case ActionTypes.ADD:
        if (newVal >= stock || (newVal !== null && value >= (stock - minStock))) {
          setMaxQtyMsgVisible(true);
          return;
        }
        newVal++;
        break;
      case ActionTypes.REMOVE:
        if (newVal > 0) newVal--;
        break;
      default:
        break;
    }

    setValue(newVal);
    updateProductGlobalState(bundle_id, newVal);

    try {
      await addToCartCallback(bundle_id, newVal, action);
      if (action === ActionTypes.ADD) {
        gtagEvent('grutto-product-addtocart');
      } else {
        gtagEvent('grutto-product-removefromcart');
      }
    } catch (error) {
      console.error('Error adding/removing from cart:', error);
    }
  };

  return (
    <>
      <div className={`quantity product-${bundle_id} ${value > 0 ? "enabled" : ""}`}>
        <a className={`plus-btn farmshop-add-to-cart ${value >= stock || (minStock !== null && value >= (stock - minStock)) ? 'disabled' : ''}`} role="button" onClick={() => addtocart(ActionTypes.ADD)}>
          <span><W>farmshop.btn</W></span>
          <i className='icon-plus'></i>
        </a>
        <span className='number'>
          <input type="text" value={value} disabled />
        </span>
        <a className="minus-btn" role="button" onClick={() => addtocart(ActionTypes.REMOVE)}>
          <i className='icon-minus'></i>
        </a>
      </div>

      {maxQtyMsgVisible && (
        <div className='msg'>
          {t('subscription.response.maximum_quantity')}
        </div>
      )}
    </>
  );
}

export default FarmshopCartButton;