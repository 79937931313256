import React, {useEffect, useState, Fragment, Suspense} from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, matchPath } from 'react-router-dom';
import Home from '../pages/static/Home';
import Mission from '../pages/static/Mission';
import NotFound from '../pages/static/404';
import Statics from '../pages/static/Statics';
import Meatcuts from '../pages/static/Meatcuts';
import Products from '../pages/Products';
import Faq from '../pages/FAQ';
import Recipes from '../pages/Recipes';
import Farmers from '../pages/Farmers';
import Breeds from '../pages/Breeds';
import Coupons from '../pages/Coupons';
import Header from '../pages/inc/Header';
import Footer from '../pages/inc/Footer';
import Farmshop from '../pages/Farmshop';
import Subscription from '../pages/Subscription';
import { useTranslation } from 'react-i18next';
import {isNil, upperFirst, isEmpty, last} from 'lodash';
import Blog from '../pages/Blog';
import Loader from '../components/common/Loader';
import {urlMaps} from './../utils/config';

import {currentLang, isProductType, checkRedirects, webp_support} from './../utils/functions.js';

const App = () =>{

    const {pathname} = useLocation();
    const [URLS, setURLS] = useState([]);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const [sectionClass, setSectionClass] = useState(null);
    const [pageClass, setPageClass] = useState(null);

    const maybeSmoothScroll = e => {
      var clicked_link = e.target.closest( 'a' );
      if ( clicked_link && clicked_link.getAttribute( 'href' ) && clicked_link.getAttribute( 'href' ).startsWith( '#' ) ) {
        let scrollToElement = document.querySelector( clicked_link.getAttribute( 'href' ) + ', [name="' + clicked_link.getAttribute( 'href' ).slice(1) + '"]' );
        if (  scrollToElement ) {
          e.preventDefault();
          scrollToElement.scrollIntoView( { behavior: 'smooth' } );
        }
      }
    }

    useEffect(() => {
        webp_support();

        const currentLanguageMaps = urlMaps[currentLang()];

        if (!isNil(currentLanguageMaps) && URLS.length === 0) {
            setURLS(currentLanguageMaps);
            let arr = [];
            let animals = [];
            let urls = {};

            currentLanguageMaps.forEach(item => {
                urls[item.name] = item.pattern;
                if (item['items']) {
                    item['items'].forEach(row => {
                        let tempURL = row.pattern.replace('/' + currentLang(), '');
                        if (!isEmpty(row.pattern) && !isEmpty(tempURL)) {
                            arr.push(row);
                            if (row.component === 'products') {
                                animals[row.name] = row.pattern.split('/')[2];
                            }
                        }
                    });
                }
            });

            // If you really need to set these to the global window object
            window.animals = animals;
            window.urls = urls;

            setItems(arr);
            setLoading(false);
        }
    }, [urlMaps]);

    useEffect(() => {
      if(isProductType()){
        setSectionClass('products');
      } else if(isProductType('farmshop-products')){
        setSectionClass('farmshop-products');
      } else if(isProductType('subscription')){
        setSectionClass('subscription');
      } else {
        setSectionClass('');
      }

      var slug_r = last(window.location.href.replace(/\/$/, "").split('/'));
      if(slug_r == currentLang()){
        setPageClass('home');
      }else{
        setPageClass(slug_r.split('?')[0].split('#')[0]);
      }
      checkRedirects();
    }, [pathname])

    const componentsMap = {
      faq: <Faq />,
      statics: <Statics />,
      mission: <Mission />,
      products: <Products />,
      breeds: <Breeds />,
      meatcuts: <Meatcuts />,
      farmers: <Farmers />,
      recipes: <Recipes />,
      coupons: <Coupons />,
      'farmshop-products': <Farmshop />,
      'subscription': <Subscription />,
      blogs: <Blog />
    };

    if (loading) {
      return <Loader />
    }

    return (
        <div className={"App " + (currentLang()) + (sectionClass ? " " + sectionClass : '') + " " + (pageClass ? pageClass : '')}>
            {/* <TrackPageView /> */}
            <Fragment>
            <Header />
            <div className='page--body' onClick={maybeSmoothScroll}>
              <Routes>
                <Route path="/" element={<Navigate to={"/" + currentLang()} replace />} />
                <Route exact path={"/" + currentLang()} element={<Home />} />
                <Route exact path={"/" + currentLang() + "/404"} element={<NotFound />} />
                <Route path={"/" + currentLang() + "/" + t("menu.animal") + "/:filter?"} element={<Home />} />

                {items && items.map((item, index) => {
                    if (!isEmpty(item.pattern)) {
                      return (
                        <Route key={'item_url_' + index} path={item.pattern} name={item.name} element={componentsMap[item.component]}/>
                      )
                    }
                })}

                {URLS.map((item, index) => {
                    if (!isEmpty(item.pattern)) {
                      return (
                        <Route key={'url_num_' + index} path={item.pattern} name={item.name} element={componentsMap[item.component]} />
                      )
                    }
                })}

                {URLS &&
                  <Route exact path="*" element={<Statics />} />
                }

              </Routes>
            </div>
            <Footer />
            </Fragment>
        </div>
    );
}

export default App;