import React, {useState, useEffect, Fragment} from 'react'
import parse from 'html-react-parser';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import {currentLang} from '../../../utils/functions';

function Newsletter({data}) {
    const [email, setEmail] = useState(null);
    const [messageType, setMessageType] = useState(null);
    const [message, setMessage] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const { t } = useTranslation();
    let {mailchimpUrl, mailchimpGroupId, mailchimpId, buttonText, position, className, body, body2} = data;

    const handleSubmit = e => {
        e.preventDefault();
        if(email){
                var url = '/'+currentLang()+'/shop/wp-json/grutto/v2/klaviyo/subscribe';

                var postdata = new FormData();
                postdata.append('email', email);

                setMessageType(null);
                try{
                    setDisabled(true);
                    axios.post(url, postdata, {timeout:10000}).then(response=>{
                        if (response && response.data) {
                            setMessageType(response.data.message);
                            var msg = t('newsletter.'+response.data.message);

                            if(response.data.message){
                                msg = response.data.message
                            }
                            setMessage(parse(msg));

                        }
                        setDisabled(false);
                    }).catch(() => {
                        setMessageType('error');
                        setMessage(t('newsletter.subscription_error'));
                        setDisabled(false);
                    });
                }catch(err){
                    setMessageType('error');
                    setMessage(t('newsletter.subscription_error'));
                    setDisabled(false);
                    console.log(err);
                }  
        }
    }

    return (
        <Fragment>
        {buttonText &&
            <div className={"module__newsletter"+(!isNil(className) ? " "+className : "")+(!isNil(position)? " newsletter__text-"+position : "")}>
                <div className="container">
                <div className="row newsletter__holder">
                    <div className="body">
                        {!isNil(body) && parse(body)}
                    </div>
                    <div className="form">
                        <div className="form__body">
                            {!isNil(body2) && parse(body2)}
                        </div>
                        <form method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate onSubmit={(e)=>{handleSubmit(e)}}>    
                            <div id="mc_embed_signup_scroll">
                                <div className="mc-field-group">
                                    <input type="email" value={email ? email : ''} placeholder={t('newsletter.placeholder')} name="EMAIL" className="required email form-control" id="mce-EMAIL" onChange={(e)=>{setEmail(e.target.value)}} />
                                    <input type="submit" value={buttonText}  name="subscribe" id="mc-embedded-subscribe" className={"btn btn--red"+(disabled ? " btn--disable" : "")} disabled={disabled} />
                                </div>
                                {messageType && message &&
                                <div className={"message message--"+messageType}>
                                    {parse(message)}
                                </div>
                                }
                                <div id="mce-responses" className="clear">
                                    <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                                    <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        }
        </Fragment>
    )
}

export default Newsletter
