import React, {useState, useEffect, Fragment} from 'react'
import flatstore from 'flatstore';
import {useLocation, Link} from 'react-router-dom';
import ProductAdd from '../ProductAdd';
import W from '../../../common/Word';
import { useTranslation } from 'react-i18next';
import { currentLang } from '../../../../utils/functions';
import parse from 'html-react-parser';
import {isEmpty, values, toNumber} from 'lodash';
import PackageCta from '../PackageCta';

const ProductContent = ({data, handleBasket, createURL, raw_data, perperson, stockStatus, shopUrl, btnDisable}) => {
    const [userSum, setUserSum] = useState(0);
    const [totalPack, setTotalPack] = useState(0);
    const [totalPieces, setTotalPieces] = useState(0);
    const [cutPackages, setCutPackages] = useState(null);
    const [totalCutPackages, setTotalCutPackages] = useState(null);
    const [mincedPackages, setMincedPackages] = useState(null);
    const [packageSumStyle, setPackageSumStyle] = useState("text--green");
    const [alertTotalPackages, setAlertTotalPackages] = useState(null);
    const [diffTotalPack, setDiffTotalPack] = useState(0);
    // const [showProductContent, setShowProductContent] = useState(raw_data.slug === 'bio-angus-paket-maxi' ? false : true);
    const [params, setParams] = useState({});
    const { t } = useTranslation();
    const { pathname } = useLocation();

    var perperson_fixed = perperson.toFixed(2).replace('.', t('price_format_replace'));
    let shop_price_kg = raw_data.shop_package_price_per_kilo.toFixed(2);
    const price_per_kg = shop_price_kg.replace('.', ',');
    useEffect(() => {

    }, [pathname])

    useEffect(() => {

       var total = 0;
       var totalCuts = 0;
       var haveMinced = 0;
       var totalPieces = 0;
       data.map(item => {
            if(item.shop_packageproduct_type == 'cut'){
                setCutPackages(item.cut_packages);

                if(item.cut_packages !== undefined){
                    total += item.cut_packages.reduce(function(i, val) { return i + val.meals; }, 0);
                    totalCuts += item.cut_packages.reduce(function(i, val) { return i + val.meals; }, 0);
                    totalPieces += item.cut_packages.reduce(function(i, val) { return i + val.total_pieces; }, 0);
                    //setUserSum(userSum + total);
                }
            }

            if(item.shop_packageproduct_type == 'minced'){
                setMincedPackages(item.minced_packages);
                total += item.minced_packages.reduce(function(i, val) { return i + val.meals; }, 0);
                totalPieces += item.minced_packages.reduce(function(i, val) { return i + val.total_pieces; }, 0);
                //setUserSum(userSum+total);
                //setTotalCuts(totalCuts + total);

                var firstParams = {};
                if(item.minced_packages){
                    item.minced_packages.map(item=>{
                        firstParams[item.name] = item.meals;
                        //return item.name + "=" + item.meals;
                    }).join("&");
                }
                //setUrl(firstUrl);
                setParams(firstParams);
                haveMinced = 1;
            }
            setTotalPieces(totalPieces);
            setTotalPack(total);
            setUserSum(total);
       });
       setTotalCutPackages(totalCuts);
       if(haveMinced == 0){
           createURL({});
       }

    }, [data, pathname]);

    useEffect(() => {
        if(userSum != totalPack){
            setPackageSumStyle("text--red");
            handleBasket(0);
            if(userSum > totalPack) {
                setAlertTotalPackages(t("product.product_content_remove"));
                setDiffTotalPack( userSum - totalPack );
            } else {
                setAlertTotalPackages(t("product.product_content_pickmore"));
                setDiffTotalPack( totalPack - userSum );
            }
        }else{
            setPackageSumStyle("text--green");
            handleBasket(1);
            setDiffTotalPack(0);
        }
    }, [userSum]);

    const sum = value =>{
        if(value > 0){
            setUserSum(userSum+1);
            setDiffTotalPack(diffTotalPack+1);
            handleBasket(0);
        }else{
          setUserSum(userSum-1);
          handleBasket(1);
          setDiffTotalPack(diffTotalPack-1);
        }
    }
    useEffect(() => {
        if(!isEmpty(params)){
            var total = 0;
            for(var i in params){
                total += params[i];
            }
            setUserSum(totalCutPackages+total);
        }
        if((values(params).some(x => x !== undefined))){ // check if object is empty
            flatstore.set('product_meatoptions_data', params);
        }
        createURL(params);

    }, [params])

    const urlChange = (name, value) =>{
        setParams({...params, [name]: value});
    }
    const getNumberFromString = str =>{
        var regex = /[+-]?\d+(\.\d+)?(\,\d+)?/g;
        var floats = str.match(regex).map(function(v) { return parseFloat(v.replace(',', '.')); });
        return toNumber(floats);
    }
    const [ toggleStepOne, settoggleStepOne] = useState(false)
    const [ toggleStepTwo, settoggleStepTwo] = useState(true)
    const hasMincedPackages = !(mincedPackages == null);

    return (
        <>
            <div className='row productContent__holder' id="productContent">
                <div className="heading"><W>product.content.package-content</W></div>
                {!hasMincedPackages &&
                    <div className={'item item-open mt-0'} style={{borderTop: "0"}}>
                        <div className='item-body'>
                            <div className="row th">
                                <div className="col-5 col-sm-5 col-md-6 col-lg-5 th-meatcuts">
                                    <W>product.content.meat-product</W>
                                </div>
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 th-pieces">
                                    <W>product.content.number</W>
                                </div>
                                <div className="col-3 col-sm-3 col-md-2 col-lg-3 th-meals">
                                    <W>abtest.meals</W>
                                </div>
                            </div>
                            <div className={"row tbody"}>
                                {cutPackages && cutPackages.map((item, index) => {
                                    return (
                                        <Fragment key={"product_cutpackages_"+index}>
                                            <div className="row border package__row" key={index}>
                                                <div className="col-5 col-sm-5 col-md-6 col-lg-5 meatcuts">
                                                    {item.frontend_name_detailpage ? (
                                                        <Link to={"/"+currentLang()+"/"+t("menu.meatcuts")+"/"+item.frontend_name_detailpage} title={item.frontend_name}>
                                                        {item.frontend_name}
                                                        </Link>
                                                    ) : (
                                                        <span>{item.frontend_name}</span>
                                                    )}
                                                </div>
                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 pieces">
                                                    {item.total_pieces} <W count={item.total_pieces} type="interval">product.{item.unit_single}</W>
                                                </div>
                                                <div className="col-3 col-sm-3 col-md-2 col-lg-3 meals">
                                                    <div className="package__meals">
                                                    {item.total_pieces} x {item.weight}{item.unit_type ? item.unit_type : 'g'}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
                {hasMincedPackages &&
                    <>
                    {/* == AB Test - START == */}
                    <div className={'item step1 d-none ab-test-tab ' + (toggleStepOne ? '' : 'item-open')}>
                        <div className='title' onClick={() => { settoggleStepOne(!toggleStepOne)}}>
                            <div className='number'><span>1</span></div>
                            <div className='text'><W>product.content.step1-title</W></div>
                        </div>
                        <div className='item-body'>
                            <div className="row th">
                                <div className="col-5 col-sm-5 col-md-6 col-lg-5 th-meatcuts">
                                    <W>product.content.meat-product</W>
                                </div>
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 th-pieces">
                                    <W>product.content.number</W>
                                </div>
                                <div className="col-3 col-sm-3 col-md-2 col-lg-3 th-meals">
                                    <W>abtest.meals</W>
                                </div>
                            </div>
                            <div className={"row tbody"}>
                                {cutPackages && cutPackages.map((item, index) => {
                                    return (
                                        <Fragment key={"product_cutpackages_"+index}>
                                            <div className="row border package__row" key={index}>
                                                <div className="col-5 col-sm-5 col-md-6 col-lg-5 meatcuts">
                                                    {item.frontend_name_detailpage ? (
                                                        <Link to={"/"+currentLang()+"/"+t("menu.meatcuts")+"/"+item.frontend_name_detailpage} title={item.frontend_name}>
                                                        {item.frontend_name}
                                                        </Link>
                                                    ) : (
                                                        <span>{item.frontend_name}</span>
                                                    )}
                                                </div>
                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 pieces">
                                                    {item.total_pieces} <W count={item.total_pieces} type="interval">product.{item.unit_single}</W>
                                                </div>
                                                <div className="col-3 col-sm-3 col-md-2 col-lg-3 meals">
                                                    <div className="package__meals">
                                                        ≃ {item.total_pieces} x {item.weight}g
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {/* == AB Test - END == */}
                    <div className={'item step1 original-tab ' + (toggleStepOne ? 'item-open' : '')}>
                        <div className='title' onClick={() => { settoggleStepOne(!toggleStepOne)}}>
                            <div className='number'><span>1</span></div>
                            <div className='text'><W>product.content.step1-title</W></div>
                        </div>
                        <div className='item-body'>
                            <div className="row th">
                                <div className="col-5 col-sm-5 col-md-6 col-lg-5 th-meatcuts">
                                    <W>product.content.meat-product</W>
                                </div>
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 th-pieces">
                                    <W>product.content.number</W>
                                </div>
                                <div className="col-3 col-sm-3 col-md-2 col-lg-3 th-meals">
                                    <W>abtest.meals</W>
                                </div>
                            </div>
                            <div className={"row tbody"}>
                                {cutPackages && cutPackages.map((item, index) => {
                                    return (
                                        <Fragment key={"product_cutpackages_"+index}>
                                            <div className="row border package__row" key={index}>
                                                <div className="col-5 col-sm-5 col-md-6 col-lg-5 meatcuts">
                                                    {item.frontend_name_detailpage ? (
                                                        <Link to={"/"+currentLang()+"/"+t("menu.meatcuts")+"/"+item.frontend_name_detailpage} title={item.frontend_name}>
                                                        {item.frontend_name}
                                                        </Link>
                                                    ) : (
                                                        <span>{item.frontend_name}</span>
                                                    )}
                                                </div>
                                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 pieces">
                                                    {item.total_pieces} <W count={item.total_pieces} type="interval">product.{item.unit_single}</W>
                                                </div>
                                                <div className="col-3 col-sm-3 col-md-2 col-lg-3 meals">
                                                    <div className="package__meals">
                                                        ≃ {item.total_pieces} x {item.weight}g
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={'item step2 ' + (toggleStepTwo ? 'item-open' : '') + (diffTotalPack != 0 ? ' show-alert' : '')}>
                        <div className='title' onClick={() => {settoggleStepTwo(!toggleStepTwo)}}>
                            <div className='number'><span>2</span></div>
                            <div className='text original'><W>product.content.step2-title</W></div>
                        </div>
                        <div className='item-body'>
                            <div className="row th">
                                <div className="col-5 col-sm-5 col-md-6 col-lg-5 th-meatcuts">
                                    <W>product.content.meat-product</W>
                                </div>
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 th-pieces">
                                    <W>product.content.number</W>
                                </div>
                                <div className="col-3 col-sm-3 col-md-2 col-lg-3 th-meals">
                                    <W>abtest.meals</W>
                                </div>
                            </div>
                            <div className={"row tbody"}>
                                {mincedPackages && mincedPackages.map((item, index) => {
                                    return (
                                        <ProductAdd key={"product_mincedpackages_"+index} defaultValue={item.meals} packageInfo={item} parentCallback={sum} urlChange={urlChange} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 alert__box">
                        <div className='col'>
                            <span className={`alert__total-vacuums bg--red text--white ${diffTotalPack != 0 ? 'active' : ''}`}><i className={"icon-warning"}></i> {alertTotalPackages} {diffTotalPack} {t('product.vacuum_interval', {postProcess: 'interval', count: diffTotalPack})}</span>
                        </div>
                    </div>
                    <div className={"item step3"}>
                        <div className='title'>
                            <div className='number'><span>3</span></div>
                            <div className='text'><W>product.content.step3-title</W></div>
                        </div>
                    </div>
                </>
                }
                <div className="steps__link">
                    <PackageCta
                        stockStatus={stockStatus}
                        shopUrl={shopUrl}
                        btnDisable={btnDisable}
                    />
                </div>
                <div className="row tfoot">
                    {raw_data.category_slug != 'grutto_farmer_package' &&
                    <div className="col-xxl-8 col-xl-12 order-xxl-0 mb-3">
                        {parse(t('abtest.content_footer', {totalPack: parseInt(raw_data.shop_package_total_meals_for_person)}))}<br />
                        {currentLang() == 'fr' &&
                            <Fragment>
                                {parse(t('abtest.content_footer_kg', {weight: raw_data.shop_package_frontend_size, price_per_kg: price_per_kg}))}
                                <br />
                            </Fragment>
                        }
                        {parse(t('abtest.content_footer2', {perperson: perperson_fixed}))}<br />
                    </div>
                    }
                </div>
                {(currentLang() == 'nl' || currentLang() == 'de') &&
                    <div className={"farmshop_link d-none mb-3 "+currentLang()+" "+(raw_data.is_organic == 1 ? ' bio--product' : '')}>
                        <Link to={t("farmshop.url")} className='btn btn--border'> <W>farmshop.cta_text</W> </Link>
                    </div>
                }
            </div>

        </>
    )
}

export default ProductContent
